import React,{Component} from "react";
import logo from '../assets/footer-logo.svg';
import {Link} from 'react-router-dom'
import fbicon from '../assets/socialfooter/facebook.webp'
import instaicon from '../assets/socialfooter/instagram.webp'
import youicon from '../assets/socialfooter/youtube.webp'

export default class Footer extends Component{

    render(){
        return(
            <footer className="mt-3 footer">
                
                <div className="row ">
                    <div className="col-12 p-4"></div>
                </div>
            <div className="row mt-2 fot text-center ml-5">
                <div className="col-xs-12 col-md-4 text-left text-center">
                        <div className="pb-3 ">
                        <img src={logo} className="mt-3" width="100"  alt=""/>
                        </div>
                        <div>
                            <p className="m-1">Accounting and Business Solutions (LLC) will give you a competitive edge in your business by providing accounting and business solutions and services. In addition, our organization provides top-class outsourced accounting and tax services to help you focus on making your business more successful, productive, and fruitful.</p>
                        </div>
                        <div>
                            
                        </div>
                        
                </div>
                
                <div className="col-xs-12 col-md-4">
                    <div className="py-3">
                        <h4 className="chimney">Our Services</h4>
                        </div>
                    <div>
                        <ul  className="p-3 fot text-center">
                            <li className="py-2" ><Link to="bookkeeping">Bookkeeping</Link></li>
                            <li className="py-2" ><Link to="payroll">Payroll</Link></li>
                            <li className="py-2"><Link to="tax">Tax</Link></li>
                            <li className="py-2" ><Link to="audit">Audit</Link></li>
                            <li className="py-2" ><Link to="business-consultation">Business Consultation</Link></li>
                            <li className="py-2" ><Link to="business-formation">Business Formation</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="col-xs-12 col-md-4">
                    <div className="py-3">
                    <h4 className="chimney">Contact Us</h4>
                    </div> 
                    <div className="my-4 ">
                    5800 Corporate Dr Ste B-2,<br/> Houston, TX 77036
                    </div>

                    <div className="mx-4 mt-3">
                                <span>
                                   <a href="#" target="_blank"><img src={fbicon} width="32" alt=""/></a>
                                </span>
                                <span className="ml-3">
                                    <a href="#" target="_blank"><img src={instaicon} width="32" alt=""/></a>
                                </span>
                                <span className="ml-3">
                                    <a href="#" target="_blank"><img src={youicon} width="32" alt=""/></a>
                                </span>
                        </div>
                    {/* <div >
                        <input className="subscribe" type="text" placeholder="Subscribe For Updates"/>
                        <button className="mt-3 sub-but">Submit</button>
                    </div> */}
                </div>
            </div>
            <div className="text-center bottom-bar">
                <small>Built with<span> &#10084;</span> and &#9749;
 </small>
            </div>
            </footer>
        );
    }
}