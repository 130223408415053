import React, {Component} from "react";
import CTA from './cta-containers'
export default class SinglePage extends Component{
    constructor(props){
        super(props);

    }
    render(){
        return(
            <div>
                <div className="row my-5">'
                    <div className="col-md-10 offset-md-1 service-res" >
                        <div className="bord-bott">
                            <h1>{this.props.service}</h1>
                        </div>
                    </div>
                    <div className="col-md-1 col-xs-0 col-sm-0"></div>
                </div>
                <div className="row my-5">
                    <div className="col-xs-12 col-sm-12 col-md-6">
                        <div className="mx-5">
                            <img className="img-fluid" src={this.props.img} alt=""/>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6">
                        <h2 className="h2service">
                            {this.props.serviceHeading}
                        </h2>
                        <ul>
                            <li>{this.props.text1}</li>
                            <li>{this.props.text2}</li>
                            <li>{this.props.text3}</li>
                            {this.props.text4 ? <li>{this.props.text4}</li>  : null }
                            {this.props.text5 ? <li>{this.props.text5}</li>  : null }
                            {this.props.text6 ? <li>{this.props.text6}</li>  : null }
                            
                            
                        </ul>
                    </div>
                </div>
                <div className="full-slide">
                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3455.26424429854!2d-95.7875752854075!3d30.000568027600007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86412b1102565819%3A0xf367bb96bbfac518!2sAmber%20Brown%20Dr%2C%20Texas%2077433%2C%20USA!5e0!3m2!1sen!2s!4v1581950877679!5m2!1sen!2s" width="100%" height="450" frameborder="0" allowfullscreen=""></iframe> */}
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3465.219220554948!2d-95.55476752399073!3d29.71340853417836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c2d74ca25fd1%3A0x5325d358eb34ed88!2s5800%20Corporate%20Dr%20b%202%2C%20Houston%2C%20TX%2077036%2C%20USA!5e0!3m2!1sen!2s!4v1695029877762!5m2!1sen!2s" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <div>
                    <CTA/>
                </div>
            </div>
        );
    }
}