import React,{Component} from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import Single from './singlepage'
import Ten from '../assets/single/10.webp'
import Eleven from '../assets/single/11.webp'
import Fourteen from '../assets/single/14.webp'
import Sixteen from '../assets/single/16.webp'
import Twelvth from '../assets/single/12.webp'
import Thirteenth from '../assets/single/13.webp'
import Carousel from './Carousel'
import Tax from './tax'
import Contact from './Pages/Contactus'
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";
import ScrollToTop from './ScrollToTop'


class Main extends Component{
  


    render(){
        return(
            <Router>
                <ScrollToTop/>
                
                <div id="main" >
                    <Navbar/>
                    <Switch>
                    <Route exact path="/" component={Carousel} />
                    <Route exact path="/bookkeeping" >  
                        <Single img={Ten} service="Accounting & Book Keeping" serviceHeading="Command Over Accounts" 
                        text1="Transactions are recorded in a systematic way" 
                        text2="Providing bookkeeping services to small and large clients with their preferred time(weekly, monthly, quarterly, yearly)"
                        text3="No standard software is required for clients"
                        text4="Simple process and exceptional service from staff with an extraordinary experience"
                        text5="Soletrader, Partnership, Corporation do not worry, we do accounts for all of them."/>
                    </Route>

                    {/* <Route exact path="/tax" >  
                        <Single img={Eleven} service="Tax Return Filing" serviceHeading="Strategic Tax Planning" 
                        text1="Firms accountant can help you figure out a new tax code that is compliance with state laws regulation" 
                        text2="The firm will prepare state, federal and local tax returns"
                        text3="Helps you to figure out how to reduce the tax you pay"
                        text4="Making year-round tax accounting service convenient"
                        />
                    </Route> */}

                    <Route exact path="/tax" >  
                        <Tax/>
                    </Route>

                    <Route exact path="/payroll" >  
                        <Single img={Fourteen} service="Payroll" serviceHeading="Payroll Management" 
                        text1="Our management will help you to develop a payroll system" 
                        text2="Prepared all necessary payroll tax returns promptly"
                        text3="Our payroll services also include tax deductions, state payroll filing, and billing records, bank reconciliation and related payroll issue"
                        
                        />
                    </Route>

                    <Route exact path="/audit" >  
                        <Single img={Sixteen} service="AUDIT-FEDERAL-STATE" serviceHeading="Compilations & Reviews" 
                        text1="Track your revenues and expenses" 
                        text2="Provide consulting on your business’s overall financial health"
                        text3="Give opinions about your company growth"
                        text4="Your financial statements are presented fairly and accurately with generally accepted accounting principles"
                        text5="The accountant will also provide forensic service for your business"
                        
                        />
                    </Route>
                    
                    <Route exact path="/business-consultation" >  
                        <Single img={Twelvth} service="Business Consultation" serviceHeading="Ideas For Financial Strategies" 
                        text1="Accountants provide advice to the client on the financial strategies by identifying the area that is negatively affecting business growth and profitability" 
                        text2="Alert you about the changes in the business or environment"
                        text3="We perform reviews to ensure the integrity of your data"
                        text4="The accountant can help you figure out your insurance needs"
                    
                        
                        />
                    </Route>

                    <Route exact path="/business-formation" >  
                        <Single img={Thirteenth} service="Business Formation" serviceHeading="Business Entity Selection" 
                        text1="The accountants can also help in the formation of different companies" 
                        text2="We help you to determine "
                        text3="What your business is worth, should you be considering a merger, acquisition or sale"
                        text4="We can assist you in evaluating the pros and cons of each entity type "
                        text5="Use of financial and business analytical tools to make a better case for approval "
                    
                        
                        />
                    </Route>

                    <Route  path="/contact-us">
                        <Contact/>
                    </Route>


                    {/* <Carousel/> */}
                    </Switch>
                    <Footer/>
                </div>
            </Router>
        );
    }
}

export default Main;