
import React from 'react';
import { Col, Button, Form, FormGroup, Input} from 'reactstrap';
import axios from 'axios'



export default class ContactForm extends React.Component {
  constructor(props){
      super(props);

      this.state={
        username : '',
        email : '',
        contact : '',
        message : ''
      }
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event){
    event.preventDefault();

    this.setState({
      [event.target.name] : event.target.value 
    });

  }
  async handleSubmit(e){
    e.preventDefault();
    axios.post('/email.php', {
      username : this.state.username,
      email : this.state.email,
      contact : this.state.contact,
      message : this.state.message
    }).then(res => console.log(res.data)).catch(err => console.log(err));

    alert("Thanks for submitting your query... Our team will reach you soon.")

  }
  render() {
    return (
      <Form action="email.php" onSubmit={this.handleSubmit}>
        <FormGroup row>
        {/* <Label for="exampleName" sm={2}>Your Name</Label> */}
        <Col sm={12} md={10}>
          <Input type="text" name="username" id="exampleName" value={this.state.username} onChange={this.handleChange} placeholder="Your Name" />
        </Col>
        </FormGroup>
        <FormGroup row>
          {/* <Label for="exampleEmail" sm={2}>Email</Label> */}
          <Col sm={12} md={10}>
            <Input type="email" name="email" id="exampleEmail" value={this.state.email} onChange={this.handleChange} placeholder="Your Email" />
          </Col>
        </FormGroup>

        <FormGroup row>
          {/* <Label for="exampleContact" sm={2}>Contact #</Label> */}
          <Col sm={12} md={10}>
            <Input type="number" name="contact" id="exampleContact" value={this.state.contact} onChange={this.handleChange} placeholder="Phone" />
          </Col>
        </FormGroup>
        <FormGroup row>
          {/* <Label for="exampleText" sm={2}>Text Area</Label> */}
          <Col sm={12} md={10}>
            <Input type="textarea" name="message" id="exampleText" value={this.state.message} onChange={this.handleChange} placeholder="Type your message here"/>
          </Col>
        </FormGroup>
        <FormGroup check row>
          <Col sm={12} md={10}>
            <Button>Submit</Button>
          </Col>
        </FormGroup>
      </Form>
    );
  }
}