import React,{Component} from "react";
import Contact from './UI/ContactForm'

export default class CTATwo extends Component{
    render(){
        return(
            <div>
                <div className="row cta-2">
                    <div className="col-6 text-center pl-3">
                        <div >

                            <h2>REQUEST A
                            CALLBACK!</h2>
                             <br/>
                        </div>
                        <div >
                            <p >
                                Anyone Looking For Edges  In The <br/> Accounting & Business. <br/>
                                Feel Free To Request A Free Consultancy Now..
                            </p>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="mt-3">

                            <Contact/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}