import React,{Component} from 'react'
import { Jumbotron } from 'reactstrap';
import ContactForm from '../UI/ContactForm'
import fbicon from '../../assets/facebook.svg'
import instaicon from '../../assets/instagram.svg'
import youicon from '../../assets/youtube.svg'
import Fade from 'react-reveal/Fade';
import Cta from '../cta-containers'


export default class ContactUs extends Component {

    render(){
        return(
            <div>
                <Jumbotron className="text-center bg-light">
                    <h1>Contact </h1>
                </Jumbotron>
                
                <div className="row">
                    <div className="col-md-6 px-5">
                        <div className="text-center">
                            <h3 className="chimney">Send Us A Message</h3>
                        </div>
                        <div className="py-5">
                            <ContactForm/>
                        </div>
                        <div>
                            <div className="text-xs-center"><h5>For Further Queries</h5></div>
                            <div className="text-xs-center"><h6> Call us at : +1 (832) 638 9087</h6></div>
                            <div className="row text-center social">
                                <div className="col-xs-4 m-3 col-md-1"><a href="#" target="_blank"> <img src={fbicon} width="32" height="32" alt=""/></a></div>
                                <div className="col-xs-4 m-3 col-md-1"><a href="#" target="_blank"><img src={instaicon} width="32" height="32" alt=""/></a></div>
                                <div className="col-xs-4 m-3 col-md-1"><a href="#" target="_blank"><img src={youicon} width="32" height="32" alt=""/></a></div>
                            </div>
                        <div></div>
                        <div></div>
                        </div>
                    </div>
                    <div className="col-md-6 text-center">
                        <h3 className="chimney">Contact Us</h3>
                        <div className="mt-5">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3465.219220554948!2d-95.55476752399073!3d29.71340853417836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c2d74ca25fd1%3A0x5325d358eb34ed88!2s5800%20Corporate%20Dr%20b%202%2C%20Houston%2C%20TX%2077036%2C%20USA!5e0!3m2!1sen!2s!4v1695029877762!5m2!1sen!2s" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>

                    </div>
                </div>
                <Cta/>
            </div>
        );
    }
}
