import React,{Component} from "react";
import call from '../assets/Call Icon.svg'
import whatsapp from '../assets/whatsapp-01.svg'

const bgstyle = { 
    backgroundColor:  "white", 
    backgroundRepeat : 'no-repeat',
    width: "100%",
  } 

export default class CTA extends Component{
    render(){
        return(
            <div>
                <div className="row text-center my-5" style={bgstyle}>
                    <div className="col-12 text-center">
                        <h1>Dont be afraid with accounting, give it a go with LLC</h1><br/>
                       <span><a href="tel:+9182302946"><img className="cta" width="300" height="68" src={call} alt="Call cta"/></a></span>
                    </div>
                </div>
            </div>
        );
    }
}