import React, {Component} from "react";
import CTA from './cta-containers'
import banner from '../assets/anbs-ban.jpg'
/* import Pro from '../assets/1.png'
import Cal from '../assets/2.png'
import Sec from '../assets/3.png'
import Fourth from '../assets/4.png' */
import one from '../assets/icon1.png'
import two from '../assets/icon-2.png'
import three from '../assets/icon-3.png'
import fourth from '../assets/icon-4.png'
import card1 from '../assets/card1.png'
import card2 from '../assets/card2.png'
import card3 from '../assets/card3.png'
import card4 from '../assets/card4.png'
import ContactForm from './UI/ContactForm'

import '../../src/tax.css'

export default class Tax extends Component{

    constructor(props){
        super(props);
    }
    render(){
        return(
            <div>
                <div className="row mainbnr" style={{
      backgroundImage: `url(${banner})`, backgroundSize: 'cover', backgroundRepeat: "no-repeat"
    }} >
                    <div className="col-6"></div>
                    <div className="col-6">
                        <h3>Get Free Consultancy</h3>
                        <div className="py-3">
                            <ContactForm/>
                        </div>
                        </div>
                </div>
                
                    {/* <img id="banner" src={banner} alt="" /> */}
                
                <div className=" py-5 text-center">
                    <h2 className="fw-b">
                    Custom Tailored Tax Services
                    </h2>
                    <p>We offer strategic tax services to all individuals and companies. Avoid unnecessary or discrete taxes.</p>
                    <div className="container">

                        <div className="row">
                            <div className="col-3">
                                <img src={one} alt="" />
                                <p className="fw-b">
                                    Concise Planning and Preparation
                                </p>
                            </div>
                            <div className="col-3">
                                <img src={two} alt="" />
                                <p className="fw-b">
                                    Smart Filing Process
                                </p>
                            </div>
                            <div className="col-3">
                                <img src={three} alt="" />
                                <p className="fw-b">
                                    Accurate Tax Collection
                                </p> 
                            </div>
                            <div className="col-3">
                                <img src={fourth} alt="" />
                                <p className="fw-b">
                                    Guidance and Interpretation of Laws
                                </p>
                            </div>
                        </div>
                    </div>
                    
                        {/* <strong>- </strong> Concise Planning and Preparation.<br/>
                        <strong>- </strong>Smart Filing Process<br/>
                        <strong>- </strong>Accurate Tax Calculation<br/>
                        <strong>- </strong>Guidance and Interpretations of Laws<br/>
                    
                    <p className="text-break mt-3">
                        <strong>
                    AnB LLC Protect what’s yours: We can help you know your potential and gain valuable spending insights. We'll help you file with confidence at no extra cost. 

                        </strong>
                    </p> */}
                </div>
                <div className="py-5 grey-bg text-center">
                    <h2 className="fw-b">
                    Features that you are looking for!
                    </h2>
                    <p>We offer strategic tax services to all individuals and companies. Avoid unnecessary or discrete taxes.</p>
                    <div className="container">
                        <div className="row">
                            <div className="col-6">
                                <div className="tax-card mb-5">
                                    <img width="150" src={card1} alt="" />
                                    <h4> Pro Tips for Filing</h4>
                                    <p>
                                    Our filing strategy will improve your financial well-being and will tell you how you have to file according to the tax fulfillment and get a maximum tax refund. If you're a basic filer and want to upload your W-2 data by simply taking a photograph. 
                                    </p>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="tax-card mb-5">
                                    <img width="150" src={card2} alt="" />
                                    <h4>Tax Planning</h4>
                                    <p>
                                    We’ll see your spending habits and guide you on how to save. Everything gets free, unlimited phone and email support. Get personalized recommendations for tax-saving opportunities and take control of your financial future. 
                                    </p>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="tax-card">
                                    <img width="150" src={card3} alt="" />
                                    <h4>Accuracy in Calculations</h4>
                                    <p>
                                    We guarantee that you will get your maximum tax refund. After all, you've earned it. Our tax plans boost your refund on returns. Save big with AnB LLC tax services. 
                                    </p>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="tax-card">
                                    <img width="150" src={card4} alt="" />
                                    <h4> Minimize Deduction of Taxes</h4>
                                    <p>
                                    Enhance your tax return. We will ensure that you receive every penny you're entitled to, or we'll pay you back the amount that you were owed. We can assure you of the accuracy of your refund and will refund you in the event of penalties. 
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row py-5 ">
                            <div className="col-3 text-center">
                                <img className="sideImg" src={Pro} alt="" />
                            </div>
                            <div className="col-9 text-left">
                                <h3>Pro Tips for Filing:</h3>
                                <p>
                                Our filing strategy will improve your financial well-being and will tell you how you have to file according to the tax fulfillment and get a maximum tax refund. If you're a basic filer and want to upload your W-2 data by simply taking a photograph. 
                                </p>
                            </div>
                        </div>
                        <div className="row py-5 ">
                            <div className="col-9 text-left">
                                <h3>Accuracy in Calculations:</h3>
                                <p>
                                We guarantee that you will get your maximum tax refund. After all, you've earned it. Our tax plans boost your refund on returns. Save big with AnB LLC tax services.
                                </p>
                            </div>
                            <div className="col-3 text-center">
                                <img className="sideImg" src={Fourth} alt="" />
                            </div>
                        </div>
                        <div className="row py-5 ">
                            <div className="col-3 text-center">
                                <img className="sideImg" src={Sec} alt="" />
                            </div>
                            <div className="col-9 text-left">
                                <h3>Minimize Deduction of Taxes:</h3>
                                <p>
                                Enhance your tax return. We will ensure that you receive every penny you're entitled to, or we'll pay you back the amount that you were owed. We can assure you of the accuracy of your refund and will refund you in the event of penalties.
                                </p>
                            </div>
                        </div>
                        <div className="row py-5 ">
                            <div className="col-9 text-left">
                                <h3>Tax Planning:</h3>
                                <p>
                                We’ll see your spending habits and guide you on how to save. Everything gets free, unlimited phone and email support. Get personalized recommendations for tax-saving opportunities and take control of your financial future.
                                </p>
                            </div>
                            <div className="col-3 text-center">
                                <img className="sideImg" src={Cal} alt="" />
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="row py-5">
                    <div className="col-12">
                        
                    </div>
                </div>
                {/* <div className="row py-5 bg-cus mb-5 text-center text-white">
                            <div className="col-6 ">
                            <h3 className="chimney">Send Us A Message</h3>
                            <div className="py-3">
                                <h2>File 100% free from start to finish.</h2>
                                <p>Filing for free makes Tax Season the best season of all.</p>

                            </div>
                            </div>
                            <div className="col-6">
                            <ContactForm/>
                                
                            </div>
                </div> */}
            </div>
        );
    }
}