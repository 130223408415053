import React,{Component} from 'react';
import logo from '../accounting.svg';
import {
    Navbar,
    NavItem,
    NavbarBrand,
    Nav,
}   from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileAlt,  } from '@fortawesome/free-solid-svg-icons'
import {Link} from 'react-router-dom'
import $ from 'jquery'



export default class NavBar extends Component{

    constructor(props){
        super(props);

        this.openNav = this.openNav.bind(this);
        this.closeNav = this.closeNav.bind(this);
    }
    componentDidMount(){
                window.onscroll = function() {scrollFunction()};

        function scrollFunction() {
        if (document.body.scrollTop > 70 || document.documentElement.scrollTop > 70) {
             $(".myNav").css('width', '100%');
             $(".myNav").css('position', 'fixed'); 
             $(".myNav").css('top', '0%'); 
             $(".navbar").css('padding-top', '0'); 
             $(".navbar").css('padding-bottom', '0'); 
             $("#navButton").css('font-size', '35px'); 
             $(".navbar").css('border-bottom', '1px solid #ccd3da'); 

        } else {
             $(".myNav").css('position', 'static');
             $("#navButton").css('font-size', '40px'); 
             $(".navbar").css('padding-bottom', '0.5rem');
             $(".navbar").css('border-bottom', 'none');  
        }
        }
    }

    openNav(){
        document.getElementById("myNav").style.display = "block";
    
    }

   
    
    closeNav(){
        document.getElementById("myNav").style.display = "none";
        
    }
    

    render(){
        return(
            <div >
                <div className="row ">
                    <div className="col-12 text-center top-header py-1">
                       <strong> Anyone Looking For Edges In The Accounting & Business</strong>
                    </div>
                    <div className="col-12 text-center py-3">
                    
                    5800 Corporate Dr Ste B-2, Houston, TX 77036, United States of America | <a href="tel:+832-351-0418">832-351-0418</a>  | Email : <a href="mailto:info@anbsllc.com">info@anbsllc.com</a> 
                    </div>
                </div>
                <Navbar className="myNav" >

                    <div id="myNav" className="overlay">
                    <a href="#" className="closebtn" onClick={this.closeNav}>&times;</a>
                    <div className="overlay-content">
                        <Link to="#" onClick={this.closeNav}>Home</Link>
                        <Link to="bookkeeping" onClick={this.closeNav}>Bookkeeping</Link>
                        <Link to="tax" onClick={this.closeNav}>Tax</Link>
                        <Link to="payroll" onClick={this.closeNav}>Payroll</Link>
                        <Link to="audit" onClick={this.closeNav}>Audit</Link>
                        <Link to="business-consultation" onClick={this.closeNav}>Business <br/> Consultation</Link>
                        <Link to="business-formation" onClick={this.closeNav}>Business <br/> Formation</Link>
                        <Link to="contact-us" onClick={this.closeNav}>Contact Us</Link>
                    </div>
                    </div>
                    
                    <Link to="/"><img src={logo} width="100"  alt="anbsllc"/></Link>
                    <Nav id="menus">
                        <NavItem  className="mr-5 nav-item1">
                           <Link to="/"> Home</Link>
                        </NavItem>
                        <NavItem  className="mr-5 nav-item1">
                           <Link to="bookkeeping"> Bookkeeping</Link>
                        </NavItem>
                        <NavItem  className="mr-5 nav-item1">
                            <Link to="tax"> Tax</Link>
                        </NavItem>
                        <NavItem  className="mr-5 nav-item1">
                        <Link to="payroll"> Payroll</Link>
                        </NavItem>
                        <NavItem  className="mr-5 nav-item1">
                            <Link to="audit"> Audit</Link>
                        </NavItem>
                        <NavItem  className="mr-5 nav-item1">
                            <Link to="business-consultation"> Business Consultation</Link>
                        </NavItem>
                        <NavItem  className="mr-5 nav-item1">
                            <Link to="business-formation"> Business Formation</Link>
                        </NavItem>
                        <NavItem  className="mr-5 nav-item1">
                            <Link to="contact-us"> Contact Us</Link>
                        </NavItem>
                    </Nav>
                    {/* <div className="whatsapp-icon"><a href="https://api.whatsapp.com/send?phone=923352226107" target="_blank"  rel="noopener noreferrer"><img width="16" height="16" src={whatsapp} alt=""/></a></div>
                    <div className="parent-mobile "><div className="p-2 mt-1"><FontAwesomeIcon className="famobile" icon={faMobileAlt}/></div><div className="py-3 number-container"><a href="tel:+92335-2226107"> 0335-2226107</a></div></div> */}
                    <div className="menu-resp" onClick={this.openNav} >
                        <span className="mob-menu">&#9776;</span> 
                    </div>
                </Navbar>
            </div>
        );
    }
}