import React,{Component} from 'react';


class VideoBackground extends Component{

    render(){
        return(
            <div className="videoBanner">
                <video autoPlay muted loop  id="myVideo">
                    <source src="https://res.cloudinary.com/dwwpygohb/video/upload/v1582123805/ANBSLLC/_MAIN_COMP1_foolbd.mp4" />
                    Your browser does not support HTML5 video.
                </video>
            </div>
        );
    }
}

export default VideoBackground;