import React,{Component} from "react";
import ContactForm from './UI/ContactForm';
import Videoslider from './Video-background'
import Ctabanner from './cta-containers'
import $ from 'jquery'
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import CTAT from './cta-banner-2'
import serv1 from '../assets/accountingandbookkeeping.webp'
import tax from '../assets/taxservices.webp'
import audit from '../assets/audit.webp'
import tax1 from '../assets/single/16.webp'
import {Link} from 'react-router-dom'


 


export default class Footer extends Component{
   
    componentDidMount(){
        
        //var width = $(window).width() +"px";
        
        
        

        

    
    }
    render(){
        return(
            <div > 
                <Zoom>
                    <Videoslider/>
                </Zoom>
                
                <div className="row full-card">
                    <div className="col-md-12 text-center mt-5 py-5">
                    <Fade top>
                        <h2 className="chimney">Our Services</h2>
                    </Fade>
                    </div>
                    
                    <div className="col-xs-12 col-sm-12 col-md-4 text-center">
                        <Link to="/bookkeeping">        
                        <h4>Accounting & Bookkeeping</h4>
                        <img src={serv1} alt="" className="my-4"/>
                        <div>
                            <ul className="li-colors text-left">
                                <li>Managing trade accounts </li>
                                <li>Maintaining hundreds of accounts and thousands of transaction</li>
                                <li>Electronic speed and time constraint </li>
                            </ul>
                        </div>
                        <div>
                            <button className="learn-more my-3">Learn More</button>
                            
                        </div>
                        </Link  >
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-4 text-center">
                        <Link to="/tax">
                        <h4>Tax Return Filing</h4>
                        <img src={tax} className="my-4" alt=""/>
                        <div>
                            <ul className="li-colors text-left">
                                <li>An experienced staff who are up to date <br/> with tax laws </li>
                                <li>Plans and ideas on how to maximize <br/>savings from taxes </li>
                                <li>Filing is done timely and according<br/> to state laws </li>
                                
                            </ul>
                        </div>
                        <div>
                            <button className="learn-more my-3">Learn More</button>
                        </div>
                        </Link>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-4 text-center">
                        <Link to="/payroll">
                        <h4>Payroll Services</h4>
                        <img  className="my-4" src={audit} alt=""/>
                        <div>
                            <ul className="li-colors text-left">
                                <li>Reconciliation of individual with banks </li>
                                <li>Timely effective and accurate </li>
                                <li>Options of checking payrolls and<br/> auto-deposit </li>
                                <li>Efficient management will submit and <br/>compile income tax liabilities </li>
                                <li>Cheap rate of payroll services </li>
                            </ul>
                        </div>
                        <div>
                            <button className="learn-more my-3">Learn More</button>
                        </div>
                        </Link>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-4 text-center my-5">
                        <Link to="/audit">
                        <h4>Audit - Federal - State</h4>
                        <img width="340" height="170" src={tax1} alt="" className="my-4"/>
                        <div>
                            <ul className="li-colors text-left">
                                <li>Assurance of financial statements </li>
                                <li>Review of IFRS</li>
                                <li>Compiling of financial statements </li>
                                <li>Quality reviews for stakeholders </li>
                                <li>A true and fair view of statements<br/> according to IFRS and GAAP</li>
                            </ul>
                        </div>
                        <div>
                            <button className="learn-more my-3">Learn More</button>
                        </div>
                        </Link>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-4 text-center my-5">
                        <Link to="business-consultation">
                        
                        <h4>Free Business Consultation</h4>
                        <img src={tax} className="my-4" alt=""/>
                        <div>
                            <ul className="li-colors text-left">
                                <li>Helping to keep your business in order </li>
                                <li>Full and quality service for accounting<br/> and tax </li>
                                <li>Expert opinions and guidance </li>
                                <li>Track record of working with 10 best organizations </li>
                            </ul>
                        </div>
                        <div>
                            <button className="learn-more my-3">Learn More</button>
                        </div>
                        </Link>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-4 text-center my-5">
                        <Link to="business-formation">
                        <h4>Business Formation</h4>
                        <img  className="my-4" src={serv1} alt=""/>
                        <div>
                            <ul className="li-colors text-left">
                                <li>Judicious relation with executives</li>
                                <li>Tactics for business growth </li>
                                <li>Quality material for managing<br/> businesses</li>
                                <li>Track record of successfully supporting<br/>     fortune 10 </li>
                            </ul>
                        </div>
                        <div>
                            <button className="learn-more my-3">Learn More</button>
                        </div>
                        </Link>
                    </div>
                </div>

                    
                <Ctabanner/>
                <div className="row">
                    <div className="col-12 p-3">
                        {/* Empty Space */}
                    </div>
                </div>
                

                <Fade>
                <div className="full-slide">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3465.219220554948!2d-95.55476752399073!3d29.71340853417836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c2d74ca25fd1%3A0x5325d358eb34ed88!2s5800%20Corporate%20Dr%20b%202%2C%20Houston%2C%20TX%2077036%2C%20USA!5e0!3m2!1sen!2s!4v1695029877762!5m2!1sen!2s" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                </Fade>
                <div className="row">
                    <div className="col-12 p-3">
                        {/* Empty Space */}
                    </div>
                </div>
                <Fade>
                    <CTAT/>
                </Fade>
            </div>
        );
    }
}